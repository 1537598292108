const SET_LOADING = "GLOBAL/SET_LOADING";

export default function reducer(state = { isLoading: "none" }, action) {
  
  switch (action.type) {
    // do reducer stuff
    case SET_LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setLoading(isLoading) {
  
  return { type: SET_LOADING, payload: { isLoading } };
}

export function setLoadingAsync(isLoading) {
  return (dispatch) => {
    if (isLoading) {
      dispatch(setLoading(isLoading));
      return;
    }

      dispatch(setLoading(isLoading));
  };
}
