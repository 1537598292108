import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
//import VerifikasiOtp from "./pages/VerifikasiOtp/VerifikasiOtp";
import { getToken } from "./utils/cookie";
import { CSpinner } from "@coreui/react";
import Loading from "./components/Loading";
const TheLayout = lazy(() => import('./containers/TheLayout'));
const Login = lazy(() =>import("./pages/Login/Login"));
const LoginSuper = lazy(() =>import("./pages/LoginSuper/LoginSuper"));
const Logout = lazy(() =>import("./pages/Login/Logout"));
const Registrasi = lazy(() =>import("./pages/Registrasi/Registrasi"));
const LupaPassword = lazy(() =>import("./pages/LupaPassword/LupaPassword"));
const VerifikasiAkun = lazy(() =>import("./pages/VerifikasiAkun/VerifikasiAkun"));
const VerifikasiOtp = lazy(() =>import("./pages/VerifikasiOtp/VerifikasiOtp"));
const VerifikasiOtpno = lazy(() =>import("./pages/VerifikasiOtp/VerifikasiOtpno"));
const AturPassword = lazy(() =>import("./pages/AturPassword/AturPassword"));
const StatusAkun = lazy(() =>import("./pages/StatusAkun/StatusAkun"));
const DaftarUser = lazy(() =>import("./pages/SuperUser/DaftarUser/DaftarUser"));
const AktivasiUser = lazy(() =>import("./pages/SuperUser/AktivasiUser/AktivasiUser"));
const KasirTransaksi = lazy(() =>import("./pages/Kasir/Transaksi/Transaksi"));
const ManajemenCabang = lazy(() =>import("./pages/Owner/ManajemenCabang/ManajemenCabang"));
const ManajemenKaryawan = lazy(() =>import("./pages/Owner/ManajemenKaryawan/ManajemenKaryawan"));
const LaporanTransaksi = lazy(() =>import("./pages/Owner/LaporanTransaksi/LaporanTransaksi"));
const LaporanInventori = lazy(() =>import("./pages/Owner/LaporanInventori/LaporanInventori"));
const LaporanTren = lazy(() =>import("./pages/Owner/LaporanTren/LaporanTren"));
const ForgotVerif = lazy(() =>import("./pages/LupaPassword/VerifikasiAkun"));


export const App = (props) => {
  return (
    <Router>
      <Suspense fallback={<CSpinner grow={true} className="spinner-grow"/>}>
        <Switch>
          <TheLayout>
            <Route exact path="/"><Redirect to="/login" /></Route>
            <Route exact path="/login" component={Login} />
            <Route exact path="/login-super-user" component={LoginSuper} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/lupapassword" component={LupaPassword} />
            <Route exact path="/forgot-verif" component={ForgotVerif} />
            <Route exact path="/register" component={Registrasi} />
            <Route exact path="/verifikasiakun" component={VerifikasiAkun} />
            <Route exact path="/atur-password" component={AturPassword} />
            <Route exact path="/verifikasi-otp" component={VerifikasiOtp} />
            <Route exact path="/verifikasi-otp-no" component={VerifikasiOtpno} />
            <Route exact path="/status-akun" component={StatusAkun} />
            <PrivateRoute exact path="/superuser/daftaruser" component={DaftarUser} />
            <PrivateRoute exact path="/superuser/aktivasiuser" component={AktivasiUser} />
            <PrivateRoute exact path="/kasir/transaksi" component={KasirTransaksi} />
            <PrivateRoute exact path="/owner/manajemen/cabang" component={ManajemenCabang} />
            <PrivateRoute exact path="/owner/manajemen/karyawan" component={ManajemenKaryawan} />
            <PrivateRoute exact path="/owner/laporan/transaksi" component={LaporanTransaksi} />
            <PrivateRoute exact path="/owner/laporan/inventori" component={LaporanInventori} />
            <PrivateRoute exact path="/owner/laporan/tren" component={LaporanTren} />
          </TheLayout>
        </Switch>
      </Suspense>
    </Router>
  );
};

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  const isValid = getToken(process.env.REACT_APP_KEY_TOKEN) ? true : false;
  return (
    <Route
      {...rest}
      render={props =>
        isValid ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location } 
            }}
          />
        )
      }
    />
  );
};
