import { CSpinner } from "@coreui/react";

const Loading = () => {
  return (
    <div className="outer-container">
    <div className="inner-container">
    <div className="centered-content">
        <div className="inner-loading">
        <CSpinner grow={true} className="spinner-grow" />
        </div>
        <div>
        </div>
    </div>
    </div>
</div>
  );
};

export default Loading;