const CHECK_NAME = 'CHECK_NAME';


export default function reducer(state = {authName : 'Rizky Wahyu Hidayat' }, action) {
  switch (action.type) {
    // do reducer stuff
    case CHECK_NAME:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function setAuthName(authName) {
  return { type: CHECK_NAME, payload: { authName } };
}

export function setAuthNameAsync(authName) {
  return (dispatch) => {
    if (authName) {
      dispatch(setAuthName(authName));
      return;
    }
      dispatch(setAuthName(authName));
  };
}
